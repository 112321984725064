.container {
  width: 800px;
  margin: 0 auto;
  background-color: whitesmoke;
  box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding: 3rem;
  position: relative;
  margin-top: 10rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}
h2 {
  padding-bottom: 4rem;
}
.reviewContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 10%;
}
.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content p {
  display: flex;
}
.headerContainer {
  display: flex;
  flex-direction: column;
}

input[type="radio"] {
  display: none;
}

.star {
  cursor: pointer;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.button {
  width: 25%;
  height: 1.75rem;
  background-color: rgb(236, 234, 234);
  font-size: 1.25rem;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.button:hover {
  filter: brightness(90%);
}
.button:active {
  transform: translateY(3px);
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 80%;
  padding: 0 6rem;
}
.reviewContent {
  width: 100%;
  height: 3rem;
}
input {
  border-radius: 5px;
  font-size: 1rem;
  border: 2px solid #ccc;
}
textarea {
  border-radius: 5px;
  font-size: 1rem;
  border: 2px solid #ccc;
}
.submitButton {
  width: 25%;
  height: 1.75rem;
  background-color: rgb(229, 224, 224);
  border-radius: 3px;
  font-size: 1.25rem;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.formContainer .inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formContainer .buttonContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.authModal {
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translate(-50%, 0);
  width: 24rem;
  height: 10rem;
  background-color: whitesmoke;
  box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:  space-around;
}
.link{
  text-decoration: underline;
  font-style: normal;
}
.link:hover{
  cursor: pointer;
}
.invalid{
  border-color: red;
}

@media only screen and (max-width: 900px) {
  .container{
      width: 600px;
  }
}
@media only screen and (max-width: 750px) {
  .container{
      width: 450px;
      padding: 1.5rem;
  }
  .submitButton{
    width: 35%;
  }
  .formContainer{
    width: 60%;
  }
  .reviewContainer{
    padding: 1rem 5%;
  }
}
@media only screen and (max-width: 550px) {
  .container{
      width: 300px;
      padding: 0.75rem;
  }
  .formContainer{
    width: 90%;
    padding: 0;
  }
  .submitButton{
    width: 40%;
  }
  .buttonContainer .button, .buttonContainer{
    width: 70%;
  }
  .reviewContainer{
    padding: 0.5rem 2.5%;
  }
}
