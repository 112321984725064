.container {
  max-width: 100%;
  height: fit-content;
  display: flex;
  padding: 4rem 10%;
  gap: 10%;
  position: relative;
  margin-top: -5px;
}
.image {
  width: 40%;
  height: auto;
  box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}
.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-width: 50%;
  background-color: transparent;
}
.textContainer h2{
  background-color: transparent;
  padding-bottom: 1rem;
  padding-top: 1.5rem;
}
.textContainer h4{
  padding-bottom: 0.5rem;
}
.textContainer p{
  background-color: transparent;
}



@media only screen and (max-width: 1000px) {
  .image{
    width:60%;
    height: auto;
  }
  .container{
    height:fit-content;
    flex-direction: column;
    justify-content:start;
    align-items: center;
  }
  .textContainer{
    max-width: 80%;
  }
}