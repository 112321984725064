.MainImage {
  width: 100%;
  overflow: hidden;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.mainImageContainer{
  width: 100%;
  height: fit-content;
}

.pastWorkContainer{
  width: 100%;
  height: 100%;
  margin-top: 3rem;
}