*{
    margin: 0;
    padding: 0;
    position: relative;
    background-color: whitesmoke;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

.outletcontainer{
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
}