.container {
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  /* box-shadow: 0px -4px 30px 0 rgba(0, 0, 0, 0.3); */
}

.container div {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  margin: 1rem 1rem;
}
