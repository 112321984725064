.footerContainer{
    width: 100%;
    height: 15vh;
    min-height: 10rem;
    background-color: whitesmoke;
    margin-top: 3rem;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex-direction: column;
    box-shadow: 0px -4px 30px 0 rgba(0, 0, 0, 0.3);

    position: absolute;
    bottom: -5;
    z-index: 100;
}
.footerContainer .buttonsContainer{
    padding-top: 0.5rem;
    gap: 12rem;
    display: flex;
    flex-direction: row;
}

.contactInfo{
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    gap: 12rem;
}

.link{
    color: black;
    text-decoration: none;
}
.subtitle{
    font-size: 0.8rem;
}

@media only screen and (max-width: 1000px) {
    .contactInfo p{
        font-size: 1.25rem;
    }
    .link{
        font-size: 1.25rem;
    }
}
@media only screen and (max-width: 600px) {
    .contactInfo p{
        font-size: 1rem;
    }
    .link{
        font-size: 1rem;
    }
}
@media only screen and (max-width: 500px) {
    .footerContainer{
        height: fit-content
    }
    .footerContainer .buttonsContainer{
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
    .contactInfo{
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
}
