.container {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    margin-top: 4rem;
    background-color: whitesmoke;
}

.container h2{
    padding-bottom: 1rem;
}

.container div{
    float: left;
    margin: 1rem;
}
@media only screen and (max-width: 1000px) {
    .container div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    /* get all the way in and correct a flex problem where the rating is all in a column */
    .container div div div div div{
        display: flex;
        flex-direction: row;
    }
}