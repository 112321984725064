.formContainer {
  border-radius: 4px;
  box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.3);
  width: 800px;
  height:fit-content;
  margin: 0 auto;
  margin-bottom: 4rem;
  margin-top: 10rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.formContainer h2 {
  padding-bottom: 3rem;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.form div {
  display: flex;
  gap: 2rem;
}
.form div div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
input {
  border-radius: 5px;
  font-size: 1rem;
  border: 2px solid #ccc;
}
.description {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
}
.descriptionContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.submitButton {
  width: 25%;
  height: 1.75rem;
  background-color: rgb(229, 224, 224);
  border-radius: 3px;
  font-size: 1.25rem;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.subtitle{
  margin-top: -2rem;
  font-size: 0.8rem;
}
.invalid{
  border-color: red;
}
.invalidTextarea{
  outline: none !important;
  border:1px solid red;
}
.errorText{
  font-size: 0.8rem;
  color: red;
}

@media only screen and (max-width: 900px) {
  .formContainer{
    width: 600px;
    padding: 1.25rem;
  }
  .form div{
    flex-direction: column;
  }
}
@media only screen and (max-width: 700px) {
  .formContainer{
    width: 500px;
    padding: 1rem;
  }
}
@media only screen and (max-width: 600px) {
  .formContainer{
    width: 400px
  }
}
@media only screen and (max-width: 500px) {
  .formContainer{
    width: 300px
  }
}
@media only screen and (max-width: 400px) {
  .formContainer{
    width: 200px
  }
}