.container{
    border-radius: 4px;
    box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.3);
    width: 19.5rem;
    height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem; 
    position: relative;
    padding-bottom: 1rem;
}
.container h3{
    position: absolute;
    background-color: rgba(255, 255, 255, 0.6);
    max-width:fit-content;
    padding: 0.25rem;
    border-radius: 5px;
    z-index: 5;
    top: 1rem;
    left: 0;
    right: 0;
    margin: auto;
}
.container p{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    padding: 1rem;
}

.image{
    border-radius: 4px 4px 0 0;
    width: 100%;
}

@media only screen and (max-width: 450px) {
    .container{
        width: 15rem;
        height: 18rem;
    }
}
